import { GLOBAL } from "../globals";
import { isAvailable, isPending } from "../availability";
import { getVersionedUri, normalizeUrl } from "../paths";
import { fetchBookkeepingData, fetchBookkeepingDataSync } from "./fetchData";
import { addHardcodedDeps } from "./hardCodedDeps";
import { getStoredData, putStoredData } from "./storedData";
import { updateGlobals } from "./updateGlobals";
// store pending data promises
let bookkeepingPromises = {};
/**
 * Input a list of requested uris, output the final list of uris after the necessary
 * DEPS_VERSION_MAP and DEPS_TREE are updated, and other transformation of the uris is finished.
 *
 * @param input The input list of uris
 * @param async false if synchronous
 * @return The updated uris, or a Promise if some async request needs to be waited on
 */
export function prepareRequest(input, async = true) {
    let uris = addHardcodedDeps((Array.isArray(input) ? input : [input]).map(normalizeUrl));
    // Called once all uris have a version map defined
    const getVersions = () => uris.map(getVersionedUri);
    // Find which URIs do not yet have version maps
    let missing = uris.filter((uri) => { var _a; return !((_a = GLOBAL.DEPS_VERSION_MAP) === null || _a === void 0 ? void 0 : _a[uri]) && !isAvailable(uri) && !isPending(uri); });
    if (missing.length > 0) {
        const promise = populateMissingVersions(missing, async);
        if (promise) {
            return promise.then(getVersions);
        }
    }
    return getVersions();
}
/**
 * Populate the missing versions for uris that do not have a version yet.
 *
 * @param uris The uris that are missing versions
 * @param async false if synchronous
 * @return either a promise to populate, or void
 */
export function populateMissingVersions(uris, async) {
    const stored = getStoredData(uris);
    // called at the end with the data to store and apply the data
    const process = (data) => {
        if (stored !== data) {
            putStoredData(uris, data);
        }
        updateGlobals(uris, data.linearResources, data.globals);
    };
    if (stored) {
        return process(stored);
    }
    if (async) {
        // filter out uris that already have pending promises to populate versions
        const pendingPromises = uris.map((uri) => bookkeepingPromises[uri]).filter(Boolean);
        uris = uris.filter((uri) => !bookkeepingPromises[uri]);
        let promise = fetchBookkeepingData(uris);
        if (pendingPromises.length > 0) {
            promise = promise.then(async (data) => {
                // after getting the versions, wait for the other uris that were already pending
                await Promise.all(pendingPromises);
                return data;
            });
        }
        // Keep track of this promise in case the same uri is requested, then afterwards clean it up
        const processPromise = promise.then(process);
        uris.forEach((uri) => bookkeepingPromises[uri] = processPromise);
        processPromise.finally(() => {
            uris.forEach((uri) => delete bookkeepingPromises[uri]);
        });
        return processPromise;
    }
    // synchronous requests do not need to keep track of parallel pending requests
    process(fetchBookkeepingDataSync(uris));
}
export function testReset() {
    bookkeepingPromises = {};
}

import { GLOBAL } from "./globals";
import { getIncludeId, getUnversionedPath } from "./paths";
let promises = {};
let included = {};
/**
 * Check if the given uri is already available on the page.
 *
 * @param uri The resource uri
 * @return true if the uri has already been included on the page
 */
export function isAvailable(uri) {
    let uris = new Set([uri, getUnversionedPath(uri)]);
    return !!(
    // The list of all included scripts are tracked in these maps
    included[uri] ||
        included[getUnversionedPath(uri)] ||
        // Support cases where there might be a <meta> tag injected on the page with the id of an excluded script
        document.getElementById(getIncludeId(uri)));
}
/**
 * Add a uri that is available now.
 *
 * @param uri The uri that is now available
 */
export function addAvailable(uri) {
    included[getUnversionedPath(uri)] = true;
    updateDepsTree();
}
/**
 * Is the given uri pending currently?
 *
 * @param uri The uri to check
 * @return a Promise if pending
 */
export function isPending(uri) {
    return promises[getUnversionedPath(uri)];
}
/**
 * Add a promise for a uri while it is pending.
 *
 * @param uri The uri
 * @param promise The promise
 */
export function addPending(uri, promise) {
    uri = getUnversionedPath(uri);
    promises[uri] = promise;
}
/**
 * Check all resource tags on the page to check for already included resources.
 */
export function checkResourceTags() {
    ["script", "link"].forEach(updateResourceTag);
}
/**
 * Update all script/link tags with the id and update the included mapping.
 *
 * @param tagName The type of tag
 */
function updateResourceTag(tagName) {
    const tags = document.getElementsByTagName(tagName);
    for (let i = 0, len = tags.length; i < len; i++) {
        const tag = tags[i];
        const src = tag.getAttribute(tagName === "link" ? "href" : "src");
        if (src) {
            const uri = getIncludeId(src);
            const id = tag.getAttribute("id");
            if (id == null && !document.getElementById(uri)) {
                tag.setAttribute("id", uri);
            }
            addAvailable(uri);
        }
    }
}
/**
 * Remove any included resources from DEPS_TREE.
 * This is not strictly required any more, but we do it for backwards compatibility just in case
 * someone
 */
export function updateDepsTree() {
    const tree = GLOBAL.DEPS_TREE;
    if (tree) {
        Object.values(tree).forEach((deps) => {
            for (let i = deps.length - 1; i >= 0; i--) {
                if (isAvailable(deps[i])) {
                    deps.splice(i, 1);
                }
            }
        });
    }
}
/**
 * Used for testing purpose.
 */
export function resetAvailable() {
    promises = {};
    included = {};
}

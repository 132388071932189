import { isASProxy, isASProxyRequired, isASProxyRequiredSync, resetASProxy } from "./asProxy";
import { initGlobals } from "./bookkeeping/initGlobals";
import { prepareRequest } from "./bookkeeping/prepareRequest";
import { GLOBAL } from "./globals";
import { includeResource, includeResourceSync } from "./include";
import { addAvailable, addPending, checkResourceTags, isAvailable, isPending, resetAvailable } from "./availability";
import { whenReady } from "@xweb/core-utils/src/util/whenReady";
import { addHardcodedDeps } from "./bookkeeping/hardCodedDeps";
import { normalizeUrl } from "./paths";
import { fetchBookkeepingData } from "./bookkeeping/fetchData";
import { updateGlobals } from "./bookkeeping/updateGlobals";
const log = (...args) => SMRF.debug && console.log('[smrf] ', ...args);
/**
 * Load one or more resources, using unversioned uris.
 *
 * @param uris The uris to load
 * @return A promise to load all these resources and the dependencies
 */
export async function loadPromise(input) {
    const asRequired = await isASProxyRequired();
    const uris = await prepareRequest(input);
    log("load:", { input, uris });
    const handleAfter = handleBefore();
    const stack = [...uris].reverse();
    const missed = new Set();
    while (stack.length) {
        const uri = stack.pop();
        if (!isPending(uri) && !isAvailable(uri)) {
            const deps = addHardcodedDeps(((GLOBAL.DEPS_TREE || {})[uri] || []), true).filter((u) => u !== uri);
            const missing = deps.filter((u) => !isPending(u) && !isAvailable(u)); // eslint-disable-line no-loop-func
            if (missing.length > 0 && !missed.has(uri)) {
                missed.add(uri); // don't allow infinite loops
                stack.push(uri, ...missing);
            }
            else {
                const pendingUris = deps.filter((u) => isPending(u));
                const pending = pendingUris.map((u) => isPending(u)); // eslint-disable-line no-loop-func
                if (pendingUris.length > 0) {
                    log(`waiting: ${uri} -> (${pendingUris.length}) ${pendingUris.join(", ")}`);
                }
                addPending(uri, Promise.all(pending).then(async () => {
                    if (!isAvailable(uri) && (!isASProxy(uri) || asRequired)) {
                        SMRF._downloadRequestedMap[uri] = true;
                        log("adding resource:", uri);
                        await includeResource(uri, SMRF.__scriptAttributes);
                    }
                    addAvailable(uri);
                }));
            }
        }
    }
    await Promise.all(uris.map((uri) => isPending(uri)));
    handleAfter();
}
/**
 * Load one or more files as part of a unit test.
 *
 * @param input The list of files to load
 * @return Promise to load all these files
 */
export async function loadTest(input, versioning) {
    SMRF.isTest = true;
    if (!Array.isArray(input)) {
        input = [input];
    }
    const { linearResources, globals } = await fetchBookkeepingData(input);
    updateGlobals(input, linearResources, globals);
    return loadPromise(linearResources);
}
/**
 * Load these resources and all dependencies, synchronously.
 *
 * @param uris The uris to load synchronously
 * @deprecated Avoid using synch calls if possible
 */
export function loadSync(input) {
    const asRequired = isASProxyRequiredSync();
    const uris = prepareRequest(input, false);
    log("(sync) load:", { input, uris });
    const handleAfter = handleBefore();
    const stack = [...uris];
    const missed = new Set();
    while (stack.length) {
        const uri = stack.pop();
        const deps = addHardcodedDeps((GLOBAL.DEPS_TREE || {})[uri] || [], true).filter((u) => u !== uri);
        const missing = deps.filter((u) => !isAvailable(u)); // eslint-disable-line no-loop-func
        if (missing.length > 0 && !missed.has(uri)) {
            missed.add(uri); // don't allow infinite loops
            stack.push(uri, ...deps);
        }
        else if (!isAvailable(uri) && (!isASProxy(uri) || asRequired)) {
            addAvailable(uri);
            log("(sync) add resource:", uri);
            includeResourceSync(uri);
        }
    }
    handleAfter();
}
/**
 * Handle before/after a resource include to "clean up" things.
 *
 * @return A function to call after the includes are finished
 */
export function handleBefore() {
    initGlobals();
    checkResourceTags();
    return () => {
        // Placeholder for handle after
    };
}
/**
 * Load with a callback function.
 *
 * @param uris The resources to load
 * @param callback Optional callback to call when finished
 * @param errorHandler Optional error handler
 */
async function load(uris, callback, errorHandler) {
    try {
        await loadPromise(uris);
        if (typeof callback === 'function') {
            callback();
        }
        else if (callback) {
            const fn = callback.callback;
            fn(callback.argument);
        }
    }
    catch (e) {
        console.error(e);
        errorHandler && errorHandler(e);
    }
}
/**
 * Update the window with global variables.
 */
export async function declareGlobals() {
    var _a, _b, _c, _d;
    if (GLOBAL.SMRF) {
        return;
    }
    GLOBAL.SMRF = SMRF;
    let define = (_b = (_a = GLOBAL.sap) === null || _a === void 0 ? void 0 : _a.ui) === null || _b === void 0 ? void 0 : _b.define;
    if (!define) {
        await whenReady();
        define = (_d = (_c = GLOBAL.sap) === null || _c === void 0 ? void 0 : _c.ui) === null || _d === void 0 ? void 0 : _d.define;
    }
    if (define) {
        define("xweb/smrf-loader", [], () => SMRF);
    }
}
/**
 * Clear any internal state of SMRF.
 */
export function reset() {
    resetAvailable();
    resetASProxy();
    SMRF._downloadRequestedMap = {};
}
/**
 * The SMRF namespace, this will be exposed on the window.
 */
export const SMRF = {
    load,
    loadPromise,
    loadSync,
    loadTest,
    normalizeUrl,
    updateSMRFVariables: async (uris) => {
        await prepareRequest(uris, false);
    },
    _downloadRequestedMap: {}
};
